import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CohortDescription, PatientDescription } from '../../patient';
import {
  ActivatedRoute,
  Route,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { CohortService } from '../../cohort.service';
import { PatientService } from '../../patient.service';
import {
  CognitiveDashboardTableData,
  MindcapService,
} from '../../mindcap.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { PatientCohortTableData } from '../../patient-list/patient-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {
  SelectionButtonInfo,
  SelectionService,
} from 'src/app/selection.service';
import { VestibularDashboardTableData } from '../vestibular.service';
import { VestibularService } from '../vestibular.service';
import { MindcapEventGraphComponent } from '../../Graphs/mindcap-event-graph/mindcap-event=graph.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { PatientCalendarComponent } from '../../patient-calendar/patient-calendar.component';
import { ScheduleReminderComponent } from '../../Schedule-Reminder/Schedule-Reminder.component';
import { SpiderChartComponent } from '../../Graphs/spider-chart/spider-chart.component';
import { TestListComponent } from '../../test-list/test-list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-vestibular-cohort-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    RouterLink,
    MatCheckboxModule,
    MatButtonModule,
    RouterModule,
    MindcapEventGraphComponent,
    MatCardModule,
    MatTabsModule,
    PatientCalendarComponent,
    ScheduleReminderComponent,
    SpiderChartComponent,
    TestListComponent,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './vestibular-cohort-dashboard.component.html',
  styleUrls: ['./vestibular-cohort-dashboard.component.scss'],
})
export class VestibularCohortDashboardComponent
  implements OnInit, AfterViewInit
{
  @Input('cohort')
  cohort!: CohortDescription;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort, { static: false }) sort: MatSort | null = null;

  DATA: VestibularDashboardTableData[] = [];

  dataSource = new MatTableDataSource<VestibularDashboardTableData>();
  displayedColumns: string[] = ['patientId', 'alerts', 'reports', 'therapies'];

  selectionButtons: SelectionButtonInfo[] = [];

  loading = true;

  constructor(
    private router: Router,
    public patientService: PatientService,
    public vestibularService: VestibularService,
    private selectionService: SelectionService
  ) {
    this.dataSource.data = this.DATA;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'patientId':
          return item.patientId.toLocaleLowerCase();
        case 'reports':
          return item.reports;
        case 'therapies':
          return item.therapies;
        default:
          return item[property as keyof VestibularDashboardTableData] as string;
      }
    };
  }

  ngOnInit(): void {
    if (this.cohort) {
      this.vestibularService.getDashboardNew(this.cohort.uuid).subscribe({
        next: (dashboard) => {
          // this.DATA = dashboard.patients;
          for (let item in dashboard.patients) {
            let therapyCount = 0;
            let reportCount = 0;
            let highSymptoms = 0;
            let videoError = 0;
            let highSymptomsCleared = false;
            let videoErrorCleared = false;
            let totalHighSymptoms = 0;
            let totalvideoError = 0;
            this.vestibularService
              .getReports(dashboard.patients[item].patientGuid)
              .subscribe({
                next: (data: any) => {
                  if (data.reports.length != 0) {
                    for (let element in data.reports) {
                      if (
                        data.reports[element].afterDizzinessScale > 8 ||
                        data.reports[element].afterFogginessScale > 8 ||
                        data.reports[element].afterHeadacheScale > 8 ||
                        data.reports[element].afterNauseaScale > 8 ||
                        data.reports[element].beforeDizzinessScale > 8 ||
                        data.reports[element].beforeFogginessScale > 8 ||
                        data.reports[element].beforeHeadacheScale > 8 ||
                        data.reports[element].beforeNauseaScale > 8
                      ) {
                        totalHighSymptoms++;
                        if (data.reports[element].alertsCleared != 1) {
                          highSymptoms++;
                        }
                      }
                      if (
                        data.reports[element].headMotionCompliance == -1 ||
                        data.reports[element].gazeCompliance == -1
                      ) {
                        totalvideoError++;
                        if (data.reports[element].alertsCleared != 1) {
                          videoError++;
                        }
                      }
                    }
                  }
                  if (totalHighSymptoms > 0 && highSymptoms == 0) {
                    highSymptomsCleared = true;
                  }
                  if (totalvideoError > 0 && videoError == 0) {
                    videoErrorCleared = true;
                  }
                  reportCount += data.svvReports.length + data.reports.length;
                },
                complete: () => {
                  this.vestibularService
                    .getTherapies(dashboard.patients[item].patientGuid)
                    .subscribe({
                      next: (data: any) => {
                        therapyCount += data.therapies.length;
                      },
                      complete: () => {
                        this.vestibularService
                          .getSvvAssignments(
                            dashboard.patients[item].patientGuid
                          )
                          .subscribe({
                            next: (data: any) => {
                              therapyCount += data.length;
                            },
                            complete: () => {
                              this.DATA.push({
                                patientGuid:
                                  dashboard.patients[item].patientGuid,
                                patientId: dashboard.patients[item].patientId,
                                reports: reportCount,
                                therapies: therapyCount,
                                highSymptoms: highSymptoms,
                                videoError: videoError,
                                highSymptomsCleared: highSymptomsCleared,
                                videoErrorCleared: videoErrorCleared,
                              });
                              if (
                                this.DATA.length == dashboard.patients.length
                              ) {
                                this.dataSource.data = this.DATA;
                                this.updateTable();
                              }
                            },
                          });
                      },
                    });
                },
              });
          }
        },
        complete: () => {},
      });
    }
  }

  updateTable() {
    this.dataSource.data = this.dataSource.data;
    this.loading = false;
  }

  refresh() {
    this.dataSource.data = this.dataSource.data;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //adds the selected patient to selectionButtons if it was checked and removes it if it was unchecked
  selectionChanged(checked: boolean, patientGuid: string, name: string) {
    let newEntry: SelectionButtonInfo = { name: name, guid: patientGuid };
    if (checked) {
      this.selectionButtons.push(newEntry);
    } else {
      this.selectionButtons = this.selectionButtons.filter(
        (selection) =>
          !(selection.guid == patientGuid && selection.name == name)
      );
    }
  }

  patientSelected(selected: SelectionButtonInfo) {
    this.selectionService.setData(this.selectionButtons);

    this.router.navigate(['/home/patient'], {
      queryParams: {
        id: selected.guid,
      },
    });
  }

  exportDetailed() {
    this.vestibularService.exportDetailed(this.cohort.uuid).subscribe(
      ( blob : Blob | undefined ) => {
        if ( blob ) {
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `${this.cohort.treatment}.xlsx`;
          a.click();
          URL.revokeObjectURL(objectUrl);
        }
      });
  }
}

export { SelectionButtonInfo };
