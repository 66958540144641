import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';
import { Study } from '../filter.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NetworkErrorService } from '../network-error.service';
import { catchError } from 'rxjs/operators';
import { PatientDemographics, PatientDescription } from '../patient';
import { therapyReport } from './vor-results/vor-results.component';
import { svvTherapyReport } from './svv-results/svv-results.component';
import { saveAs } from 'file-saver';

export enum Animations {
  None = 0,
  LeftToRight = 1,
  RightToLeft = 2,
  UpToDown = 3,
  DownToUp = 4,
  HorizontalOscillate = 5,
  VerticalOscillate = 6,
  RotateClockwise = 7,
  RotateCounterclockwise = 8,
}

export enum Patterns {
  NoPattern = 0,
  VerticalBars = 1,
  HorizontalBars = 2,
  Checkerboard = 3,
  Contrasting = 4,
  Dots = 5,
}

export enum Size {
  Small = 0,
  Medium = 1,
  Large = 2,
}

export enum Contrast {
  Low = 0,
  Medium = 1,
  High = 2,
}

export enum Speed {
  Slow = 0,
  MediumSlow = 1,
  Medium = 2,
  MediumFast = 3,
  Fast = 4,
}

export enum Exercises {
  HorizontalVor = 'Horizontal VOR',
  VerticalVor = 'Vertical VOR',
  HorizontalVor2 = 'Horizontal VOR Cancellation',
  VerticalVor2 = 'Vertical VOR Cancellation',
  GenericExercise = 'Generic',
}

export enum Therapies {
  Vorx1Exercise = 0,
  VorCancellation = 1,
  SubjectiveVisualVertical = 2,
  GenericExercise = 3,
}

export interface DashboardData {
  patients: VestibularDashboardTableData[];
}
export interface VestibularDashboardTableData {
  patientGuid: string;
  patientId: string;
  reports: number;
  therapies: number;
  highSymptoms: number;
  videoError: number;
  highSymptomsCleared: boolean;
  videoErrorCleared: boolean;
}

export interface Therapy {
  id: number;
  dosage: number;
  duration: number;
  speed: number;
  distance: number;
  exerciseType: Exercises;
  background: Patterns;
  optoType: string;
  comment: string;
  description: string;
  recordVideo: boolean;
  uploadVideo: boolean;
  fontSize: number;
  fontColor: string;
  backgroundColor: string;
  backgroundPattern: number;
  backgroundSize: number;
  backgroundSpeed: Speed;
  backgroundDirection: number;
  backgroundContrast: Contrast;
  effectiveStart: string;
  effectiveEnd: any;
  patientGuid: string | null;
  copiedFrom: null;
  dateCreated: string;
  lastUpdated: string;
}

export interface Report {
  patient: string;
  id: number;
  dosage: number;
  duration: number;
  speed: number;
  distance: number;
  exerciseType: Exercises;
  background: Patterns;
  optoType: string;
  comment: any;
  description: any;
  recordVideo: boolean;
  uploadVideo: boolean;
  fontSize: number;
  fontColor: string;
  backgroundColor: string;
  backgroundPattern: number;
  backgroundSize: number;
  backgroundSpeed: Speed;
  backgroundDirection: number;
  backgroundContrast: Contrast;
  effectiveStart: Date;
  effectiveEnd: Date;
}

export interface TherapyList {
  therapies: Therapy[];
}

export interface ReportList {
  therapies: Report[];
}

export interface SvvTherapy {
  id: number;
  patientGuid: string;
  trialType: string;
  comment: string;
  description: string;
}

export interface SvvActivity {
  id: number;
  description: string;
  patientGuid: string;
  trialType: string;
}

export interface SvvCommand {
  therapyId: number;
}

@Injectable({
  providedIn: 'root',
})
export class VestibularService {
  private vestibularUrl = environment.vestibularUrl;
  private therapiesUrl = `${this.vestibularUrl}/therapy`; // URL to web api
  private svvTherapiesUrl = `${this.vestibularUrl}/svvTherapy`;
  private dashboardUrl: string = `${environment.vestibularUrl}/home/dashboard`;

  activeTabSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  activeTabObs = this.activeTabSource.asObservable();

  set activeTab(v: any) {
    this.activeTabSource.next(v);
  }

  constructor(
    private http: HttpClient,
    private networkErrorService: NetworkErrorService
  ) {}

  getTherapies(patientId: any): Observable<TherapyList> {
    let params = new HttpParams();
    params = params.append('guid', patientId);

    return this.http
      .get<TherapyList>(environment.vestibularUrl + '/therapy/list', {
        params,
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError<TherapyList>(
            'TherapyList',
            undefined,
            404
          )
        )
      );
  }

  getTherapy(therapyId: number): Observable<Therapy> {
    let params = new HttpParams();
    params = params.append('therapyId', therapyId);

    return this.http
      .get<Therapy>(environment.vestibularUrl + '/therapy/getTherapy', {
        params,
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError<Therapy>(
            'Therapy',
            undefined,
            404
          )
        )
      );
  }

  getSVVTherapy(therapyId: number): Observable<SvvTherapy> {
    let params = new HttpParams();
    params = params.append('therapyId', therapyId);

    return this.http
      .get<SvvTherapy>(this.svvTherapiesUrl + '/getTherapy', {
        params,
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError<SvvTherapy>(
            'Therapy',
            undefined,
            404
          )
        )
      );
  }

  createTherapy(therapy: Therapy): Observable<Therapy> {
    return this.http.post<Therapy>(this.therapiesUrl + '/create', therapy).pipe(
      catchError((error: any) => {
        this.networkErrorService.handleError<Therapy>(
          'Therapy',
          undefined,
          404
        );
        return throwError(() => error);
      })
    );
  }

  createSvvTherapy(SvvTherapy: SvvTherapy): Observable<SvvTherapy> {
    return this.http
      .post<SvvTherapy>(this.svvTherapiesUrl + '/createTherapy', SvvTherapy)
      .pipe(
        catchError(
          this.networkErrorService.handleError<SvvTherapy>(
            'Therapy',
            undefined,
            404
          )
        )
      );
  }

  getSvvTherapyReport(therapyId: number): Observable<svvTherapyReport> {
    let params = new HttpParams();
    //params = params.append('patientGuid', patientId);
    params = params.append('id', therapyId);
    return this.http
      .get<svvTherapyReport>(environment.vestibularUrl + '/report/viewSvv', {
        params,
      })
      .pipe
      // catchError(
      //   this.networkErrorService.handleError('Therapy', undefined, 404),
      // ),
      ();
  }

  getSvvAssignments(patientId: any) {
    let params = new HttpParams();
    params = params.append('patientGuid', patientId);
    return this.http
      .get(this.svvTherapiesUrl + '/list', { params })
      .pipe(
        catchError(
          this.networkErrorService.handleError('Therapy', undefined, 404)
        )
      );
  }

  getReports(patientId: string) {
    let params = new HttpParams();
    params = params.append('patientGuid', patientId);
    return this.http
      .get(environment.vestibularUrl + '/report/listJson', { params })
      .pipe();
  }

  getVorReport(id: number): Observable<therapyReport> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http
      .get<therapyReport>(environment.vestibularUrl + '/report/view', {
        params,
      })
      .pipe();
  }

  clearAlerts(id: number) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http
      .get(environment.vestibularUrl + '/report/clearAlerts', {
        params,
      })
      .pipe();
  }

  // getReport(): Promise<[Report]>  {
  //   // let params = new HttpParams();
  //   // params = params.append("patientGuid", patientId);
  //   const url = environment.vestibularUrl;
  //   return new Promise((resolve, reject) => {
  //     lastValueFrom(this.httpClient.get(url, {params}))
  //     .then((resp: any) => {
  //       console.log(resp)
  //       if (resp.reports && Array.isArray(resp.reports)) {
  //         resolve(resp.reports as Report[])
  //       }
  //       // resolve(resp as Report[])
  //     })
  //     .catch(e => reject(e))
  //   })
  // }

  getDashboardNew(cohortGuid: string): Observable<DashboardData> {
    let params = new HttpParams();
    params = params.append('selectedCohorts', cohortGuid);
    params = params.append('filterAction', 'selectedCohorts');

    return this.http.get<DashboardData>(this.dashboardUrl, { params }).pipe(
      tap((_) => console.log('fetched dashboard')),
      catchError((error: any) => {
        this.networkErrorService.handleError<DashboardData>(
          'getDashboardNew',

          undefined
        );
        return throwError(() => error);
      })
    );
  }

  // getDashboard(
  //   cohort: CohortDescription,
  // ): Observable<VestibularDashboardTableData[]> {
  //   let patients = cohort.patients.map((x) => x.uuid);
  //   return this.http
  //     .post<VestibularDashboardTableData[]>(this.dashboardUrl, {
  //       patientGuids: patients,
  //     })
  //     .pipe(
  //       tap((_) =>
  //         this.networkErrorService.log(
  //           'fetched dashboard for cohort ' + cohort.uuid,
  //         ),
  //       ),
  //       catchError(
  //         this.networkErrorService.handleError<VestibularDashboardTableData[]>(
  //           'getDashboard',
  //           undefined,
  //         ),
  //       ),
  //     );
  // }

  public save(therapy: Therapy): Observable<string> {
    return this.http.post<string>(this.therapiesUrl + '/saveTherapy', therapy);
    // .pipe(
    //   // catchError(
    //   //   this.networkErrorService.handleError<Therapy>(
    //   //     'Therapy',
    //   //     undefined,
    //   //     404,
    //   //   ),
    //   // ),
    //);
  }

  public saveSVV(therapy: SvvTherapy): Observable<string> {
    return this.http.post<string>(
      this.svvTherapiesUrl + '/saveTherapy',
      therapy
    );
    // .pipe(
    //   // catchError(
    //   //   this.networkErrorService.handleError<Therapy>(
    //   //     'Therapy',
    //   //     undefined,
    //   //     404,
    //   //   ),
    //   // ),
    //);
  }

  getCSV(reportId: number): Observable<Blob | undefined> {
    let params = new HttpParams();
    params = params.append('id', reportId);

    return this.http
      .get(environment.vestibularUrl + '/report/csv', {
        params: params,
        responseType: 'blob',
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError('Therapy', undefined, 404)
        )
      );
  }

  getSvv(report: number, patientName: string): Observable<Blob | undefined> {
    let params = new HttpParams();
    params = params.append('id', report);
    params = params.append('patientName', patientName);
    console.log(params);
    return this.http
      .get(environment.vestibularUrl + '/report/csvSvv', {
        params: params,
        responseType: 'blob',
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError('Therapy', undefined, 404)
        )
      );
  }

  activateTherapy(therapy: Therapy, enabled: boolean) {
    let params = new HttpParams();
    params = params.append('id', therapy.id);
    params = params.append('enable', enabled);
    return this.http
      .get(environment.vestibularUrl + '/therapy/activate', { params })
      .pipe(
        catchError(
          this.networkErrorService.handleError('Therapy', undefined, 404)
        )
      );
  }

  activateSvvTherapy(therapy: SvvTherapy, enabled: boolean) {
    let params = new HttpParams();
    params = params.append('therapyId', therapy.id);
    params = params.append('active', enabled);
    return this.http
      .get(environment.vestibularUrl + '/svvTherapy/activateTherapy', {
        params,
      })
      .pipe(
        catchError(
          this.networkErrorService.handleError('Therapy', undefined, 404)
        )
      );
  }

  runCommand(command: SvvCommand) {
    return this.http.post(this.svvTherapiesUrl + '/runCommand', command).pipe(
      catchError((error: any) => {
        this.networkErrorService.handleError<SvvCommand>(
          'therapyId',
          undefined,
          404
        );
        return throwError(() => error);
      })
    );
  }

  checkForCommands() {
    return this.http.get(this.svvTherapiesUrl + '/areCommandsQueued').pipe(
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  exportDetailed(uuid: string) {
    let params = new HttpParams();
    params = params.append('uuid', uuid);

    return this.http
      .get(environment.vestibularUrl + '/report/exportAllInCohortDetailed', {
          params: params,
          responseType: 'blob'
        },
        )
      .pipe(
        catchError(
          this.networkErrorService.handleError('Export Report to XLS', undefined, 404)
        )
      );
  }
}
