// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `parent {
  width: 100%; /* OR like 95% to make shadows visible */
  height: 100%; /* OR like 95% to make shadows visible */
}

app-study-summary {
  width: calc(100% - 70px);
  height: calc(100% - 70px);
}

.tile {
  margin: 10px;
}

.tiles {
  align-content: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 4;
  display: contents;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA,EAAA,wCAAA;EACA,YAAA,EAAA,wCAAA;AACF;;AAEA;EACE,wBAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EAKI,oBAAA;EAEA,aAAA;EACA,eAAA;EAEA,2BAAA;EACA,oBAAA;AALJ;;AAQA;EACE,oBAAA;EACA,kBAAA;EACA,iBAAA;AALF","sourcesContent":["parent {\n  width: 100%;  /* OR like 95% to make shadows visible */\n  height: 100%; /* OR like 95% to make shadows visible */\n}\n\napp-study-summary {\n  width: calc(100% - 70px);\n  height: calc(100% - 70px);\n}\n\n.tile {\n  margin: 10px;\n}\n\n.tiles {\n    //display: grid;\n    //grid-template-columns: repeat(auto-fill, 400px);\n    //grid-auto-rows: minmax(100px, auto);\n\n    align-content: start;\n\n    display: flex;\n    flex-wrap: wrap;\n    //align-content: center;\n    justify-content: flex-start;\n    align-items: stretch;\n}\n\n.box1 {\n  grid-column-start: 1;\n  grid-column-end: 4;\n  display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
